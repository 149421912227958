@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.region {
  text-align: left;

  &:first-child {
    padding-top: 30px;
  }

  &:last-child {
    padding-bottom: 60px;

    @include mobile {
      padding-bottom: 30px;
    }
  }

  @include mobile {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__name {
    margin: 0 0 30px;
    font-family: $open-sans;
    font-size: 26px;
    font-weight: bold;
    line-height: 28px;
    text-transform: uppercase;
    color: $denim;

    @include mobile {
      font-size: 23px;
      line-height: 28px;
    }
  }

  &__countries {
    display: flex;
    margin: -15px;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;

    @include mobile {
      display: block;
      margin: 0;
    }
  }

  &__country {
    display: flex;
    margin: 15px;
    max-width: calc(100% / 6 - 30px);
    flex: 1 1 100%;
    align-items: center;

    @include mobile {
      margin: 15px 0;
      max-width: 100%;
    }

    .flag {
      margin-right: 10px;
      width: 30px;
      height: 30px;
      flex: 0 0 auto;
      border: 1px solid rgba($star-dust, 0.25);
    }

    &-flag {
      display: block;
    }

    &-name {
      display: block;
      font-family: $open-sans;
      font-size: 13px;
      line-height: 18px;
      color: $tuatara;
      text-align: left;
    }
  }
}
