@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.back-to-top {
  display: block;
  position: fixed;
  right: 10px;
  top: 60px;
  width: 32px;
  height: 32px;
  appearance: none;
  border: none;
  background-color: $white-50;
  box-shadow: 0 0 30px 0 rgba($black, 0.2);
  cursor: pointer;
  transition: background-color 300ms ease, opacity 300ms ease;
  z-index: 2;

  @include desktop {
    top: 110px;
  }

  &:hover {
    background-color: $white;
  }

  &::before,
  &::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 2px;
    content: '';
    background: $denim;
  }

  &::before {
    transform: translateX(-100%) translateX(1px) rotate(-30deg);
  }

  &::after {
    transform: translateX(-1px) rotate(30deg);
  }
}
